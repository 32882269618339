/* eslint-disable no-param-reassign */
import * as Yup from 'yup';
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { PostalCodeField } from '@loggi/components/src/one/formik-fields';
import {
  useFeature,
  useRemoteConfig
} from '@loggi/components/src/one/remote-config';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import SignupCompanyTemplate from '@loggi/components/src/one/sign-up-company';
import { getPublicPostalCodeCoverage } from '@loggi/components/src/one/address/address-validators';
import { MARKETING_UTMS_LOCAL_STORAGE_KEY } from '@loggi/authentication/src/screens/signup/constants';
import SignupCompanyContext from '../sign-up-company-context';
import {
  SIGN_UP_COMPANY_ROUTE,
  UPDATE_COMPANY_CONTACT_ROUTE
} from '../routes/constants';
import GoBackButton from '../shared/go-back-button.component';
import {
  createCompany,
  getEstimatedMonthlyPackagesOptions,
  getCorrespondentIntegratorsList
} from './utils';
import { useButtonLoadingStyle } from './styles';
import IntegratorsField from './integrators-field';

const MonetarySymbolAdornment = ({ t }) => {
  return (
    <InputAdornment position="start">
      {t('companyPickupForm.monetarySymbol')}
    </InputAdornment>
  );
};

MonetarySymbolAdornment.propTypes = {
  t: PropTypes.func.isRequired
};

const CompanyPickupForm = () => {
  const buttonLoadingStyle = useButtonLoadingStyle();
  const { spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [hasCoverageAreaError, setHasCoverageAreaError] = useState(false);
  const [selectedIntegrators, setSelectedIntegrators] = useState([]);
  const [hasIntegration, setHasIntegration] = useState(false);
  const { push } = useHistory();
  const location = useLocation();
  const { t } = useTranslation('signupCompanyForm');
  const enableOutOfCoverageBypass = useFeature('enable_out_of_coverage_bypass');
  const enableIntegratorsInCompanySignup = useFeature(
    'enable_integrators_in_company_signup'
  );
  const { value: estimatedPackagesRangeValue } = useRemoteConfig(
    'company_signup_monthly_packages_range'
  );
  const estimatedPackagesRange = JSON.parse(estimatedPackagesRangeValue);

  const {
    setCompanyId,
    signUpCompanyStepValues,
    updateCompanyPickupStepValues,
    setUpdateCompanyPickupStepValues
  } = useContext(SignupCompanyContext);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const initialValues = useMemo(
    () => updateCompanyPickupStepValues,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const addNewCompany = Boolean(location?.state?.addNewCompany);
  const currentStep = addNewCompany ? 0 : 3;

  const MENU_PROPS = {
    PaperProps: {
      style: {
        marginTop: spacing(1),
        maxWidth: spacing(10)
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const estimatedMonthlyPackagesOptions = estimatedPackagesRange.micro
    ? getEstimatedMonthlyPackagesOptions(estimatedPackagesRange, t)
    : [
        {
          label: t('companyPickupForm.estimatedMonthlyPackages.options.first'),
          value: 249
        },
        {
          label: t('companyPickupForm.estimatedMonthlyPackages.options.second'),
          value: 1999
        },
        {
          label: t('companyPickupForm.estimatedMonthlyPackages.options.third'),
          value: 2000
        }
      ];

  const ESTIMATED_MONTHLY_PACKAGES_SELECT = [
    {
      id: 'estimatedMonthlyPackages',
      inputLabel: t('companyPickupForm.inputLabels.estimatedMonthlyPackages'),
      options: estimatedMonthlyPackagesOptions
    }
  ];

  const companyPickupFormSchema = Yup.object().shape({
    postalCode: Yup.string().required(
      t('companyPickupForm.validation.postalCodeRequired')
    ),
    estimatedMonthlyPackages: Yup.number().required(
      t('companyPickupForm.validation.estimatedMonthlyPackagesRequired')
    ),
    averageTicket: Yup.number().required(
      t('companyPickupForm.validation.averageTicketRequired')
    )
  });

  const stopLoading = () => {
    setLoading(false);
  };

  const _onSubmit = async values => {
    setLoading(true);
    const formattedIntegrators = getCorrespondentIntegratorsList(
      selectedIntegrators
    );
    values.integrators = formattedIntegrators;
    values.isOutOfCoverage = hasCoverageAreaError;

    const { isNoL4BStatus } = signUpCompanyStepValues;
    const { estimatedMonthlyPackages, averageTicket, postalCode } = values;
    const estimatedPackagesRangeLarge = estimatedPackagesRange?.large
      ? Number(estimatedPackagesRange.large)
      : 2000;

    if (
      estimatedMonthlyPackages === estimatedPackagesRangeLarge ||
      hasCoverageAreaError ||
      isNoL4BStatus
    ) {
      setUpdateCompanyPickupStepValues({ ...values });
      push(UPDATE_COMPANY_CONTACT_ROUTE, location?.state);
    } else {
      const { cnpj, segment, sharedName } = signUpCompanyStepValues;
      const utms =
        JSON.parse(localStorage.getItem(MARKETING_UTMS_LOCAL_STORAGE_KEY)) ||
        {};
      const signupCompanyPayload = {
        shared_name: sharedName,
        cnpj,
        company_configuration_json: {
          segment,
          average_ticket: averageTicket,
          estimated_monthly_packages: estimatedMonthlyPackages,
          postal_code: postalCode,
          owner_email: authenticatedUser.email,
          ...utms
        }
      };
      if (formattedIntegrators.length) {
        signupCompanyPayload.company_configuration_json.integrators = formattedIntegrators;
      }
      await createCompany(
        t,
        { payload: signupCompanyPayload, generatePricingTable: true },
        setCompanyId,
        enqueueSnackbar,
        push,
        stopLoading
      );
    }
  };

  const redirectToPreviousStep = () => {
    push(SIGN_UP_COMPANY_ROUTE, location?.state);
  };

  const inputProps = {
    startAdornment: <MonetarySymbolAdornment t={t} />,
    inputMode: 'numeric',
    max: Number.MAX_SAFE_INTEGER,
    min: 0,
    step: 0.01
  };

  return (
    <SignupCompanyTemplate
      currentStep={currentStep}
      formTitles={t('companyPickupForm.titles', { returnObjects: true })}
      formSubtitles={t('companyPickupForm.subtitles', { returnObjects: true })}
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={companyPickupFormSchema}
        onSubmit={_onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          touched,
          values
        }) => {
          let isFormValid = isValid;

          const shouldByPassPostalCodeCoverage =
            enableOutOfCoverageBypass &&
            errors.postalCode === t('errorsMessages.originOutOfCoverageArea');

          if (shouldByPassPostalCodeCoverage) {
            delete errors.postalCode;
            setHasCoverageAreaError(true);
          }

          if (hasIntegration && !selectedIntegrators.length) {
            isFormValid = false;
          } else if (
            shouldByPassPostalCodeCoverage &&
            !errors.estimatedMonthlyPackages &&
            !errors.averageTicket
          ) {
            isFormValid = true;
          }

          return (
            <form data-testid="company-pickup-form" onSubmit={handleSubmit}>
              <PostalCodeField
                required
                isCompanySignup
                fieldName="postalCode"
                label={t('companyPickupForm.inputLabels.postalCode')}
                inputProps={{ 'data-testid': 'postal-code-field' }}
                dataTestId="postal-code-field"
                margin="normal"
                name="postalCode"
                error={Boolean(touched.postalCode && errors.postalCode)}
                helperText={touched.postalCode && errors.postalCode}
                onBlur={handleBlur}
                onDataChange={() => {
                  setHasCoverageAreaError(false);
                }}
                validate={getPublicPostalCodeCoverage}
                value={values.postalCode || ''}
              />
              {ESTIMATED_MONTHLY_PACKAGES_SELECT.map(
                ({ id, inputLabel, options }) => (
                  <FormControl
                    fullWidth
                    key={id}
                    style={{
                      marginTop: spacing(4),
                      marginBottom: spacing(4)
                    }}
                    variant="outlined"
                  >
                    <InputLabel>{inputLabel}</InputLabel>
                    <Select
                      error={Boolean(touched[id] && errors[id])}
                      id={id}
                      name={id}
                      input={<OutlinedInput label={inputLabel} />}
                      MenuProps={MENU_PROPS}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values[id] || ''}
                    >
                      {options.map(({ label, value }) => (
                        <MenuItem key={label} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {touched[id] && errors[id]}
                    </FormHelperText>
                  </FormControl>
                )
              )}
              <TextField
                label={t('companyPickupForm.inputLabels.averageTicket')}
                name="averageTicket"
                fullWidth
                error={Boolean(touched.averageTicket && errors.averageTicket)}
                helperText={touched.averageTicket && errors.averageTicket}
                inputProps={{ 'data-testid': 'average-ticket-field' }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={inputProps}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                type="number"
                value={values.averageTicket || ''}
                variant="outlined"
              />
              {enableIntegratorsInCompanySignup && (
                <IntegratorsField
                  selectedIntegrators={selectedIntegrators}
                  setSelectedIntegrators={setSelectedIntegrators}
                  hasIntegration={hasIntegration}
                  setHasIntegration={setHasIntegration}
                />
              )}
              {hasCoverageAreaError && (
                <Box pt={4}>
                  <Alert severity="info" color="info">
                    {t('companyPickupForm.outOfCoverageMessage')}
                  </Alert>
                </Box>
              )}
              <Box pt={4} width="100%">
                <Button
                  data-testid="submitButton"
                  disabled={loading || !isFormValid}
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  {!loading && t('button.text')}
                  {loading && (
                    <CircularProgress
                      data-testid="company-pickup-form-loading"
                      size={26}
                      className={buttonLoadingStyle.progress}
                    />
                  )}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
      <GoBackButton onClick={redirectToPreviousStep} />
    </SignupCompanyTemplate>
  );
};

export default CompanyPickupForm;
