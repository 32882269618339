import { PICKUPS_ROUTE, USERS_MANAGEMENT } from './routes/constants';

const LOADING_ID = 'loading-id';
const PAGE_NOT_FOUND = 'Página não encontrada';
const DISABLED_USER_MESSAGE =
  'Sua conta foi desabilitada. Por favor, entre em contato com a nossa Central de Ajuda.';
const PICKUPS_COMPANIES_ENDPOINT = 'one/api/pickups/v1/companies';
const SUBSCRIBE_NOTIFICATIONS_ENDPOINT = 'subscribe-notifications';
const SIGN_UP_USER_ALREADY_EXISTS_ERROR =
  'PreSignUp+failed+with+error+There+is+already+a+registered+user+with+email';
const SIGN_UP_GOOGLE_USER_LINK_REDIRECT =
  'PreSignUp+failed+with+error+User+account+has+been+linked+to+corresponding+Google+federated+account';
const SIGN_UP_GOOGLE_USER_IS_NOT_ENABLED = 'User+is+not+enabled';
const ACCOUNTS_COMPANIES_ENDPOINT = 'one/api/accounts/v1/companies/';
const SELECTED_COMPANY_LOCAL_STORAGE_KEY = 'loggione_selected_company';
const SUPPORT_ROLE = 'SUPPORT';
const APP_LOGGI_NEXT_PAGE_REDIRECTIONS_OPTIONS = {
  UPLOADSHEET: {
    route: companyId => `/empresas/${companyId}/pacotes/adicionar/planilha`
  },
  PAYMENTS: {
    route: companyId => `/empresas/${companyId}/financeiro`
  },
  MANAGER_USER: {
    route: companyId => USERS_MANAGEMENT.url(companyId)
  },
  NEW_PICKUP: {
    route: companyId => PICKUPS_ROUTE.path.replace(':companyId', companyId)
  },
  COMPANY_MANAGMENT: {
    route: () => `/cadastro-empresa`,
    state: { addNewCompany: true }
  }
};

export {
  LOADING_ID,
  PAGE_NOT_FOUND,
  DISABLED_USER_MESSAGE,
  PICKUPS_COMPANIES_ENDPOINT,
  SUBSCRIBE_NOTIFICATIONS_ENDPOINT,
  SIGN_UP_USER_ALREADY_EXISTS_ERROR,
  SIGN_UP_GOOGLE_USER_IS_NOT_ENABLED,
  SIGN_UP_GOOGLE_USER_LINK_REDIRECT,
  ACCOUNTS_COMPANIES_ENDPOINT,
  SELECTED_COMPANY_LOCAL_STORAGE_KEY,
  SUPPORT_ROLE,
  APP_LOGGI_NEXT_PAGE_REDIRECTIONS_OPTIONS
};
